.wrapper {
    margin-top: 70px;
}
.partner {
    height: 175px;
    background: #F3F6FE;
    border-radius: 20px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.partner .skeleton {
    width: 165px;
}
@media (max-width: 999px) {
    .wrapper {
        margin-top: 50px;
    }
    .partner {
        height: 115px;
    }
    .skeleton {
        width: 110px;
    }
}
