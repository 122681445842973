.service {
    width: 560px;
    min-height: 290px;
    border-radius: 20px;
    padding: 30px;
    background: #F3F6FE;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    gap: 20px;
}
.service h3 {
    font-size: 22px;
    text-align: center;
}
.service p {
    margin: 20px 0 0;
}
.buttons {
    display: flex;
    justify-content: space-evenly;
}
.linkMore {
    margin-left: 20px;
}

@media (min-width: 1000px) and (max-width: 1279px){
    .service {
        width: 460px;
        min-height: 310px;
        padding: 30px 20px;
    }
    .linkMore {
        margin-left: 15px;
    }
}

@media (min-width: 768px) and (max-width: 999px) {
    .service {
        width: 350px;
        min-height: 430px;
        padding: 30px;
    }
    .buttons {
        display: flex;
        flex-direction: column;
        align-self: start;
    }
    .linkMore {
        margin: 0;
        margin-top: 10px;
    }

}

@media (max-width: 767px) {
    .service {
        width: 100%;
        min-height: auto;
        margin-top: 15px;
        padding: 30px 20px;
    }
    .buttons {
        position: static;
        align-items: center;
        margin: 0 auto;
    }
    .service p {
        margin: 15px 0;
    }

}
@media (max-width: 480px) {
    .buttons {
        flex-direction: column;
        align-self: start;
    }
    .linkMore {
        margin: 0;
        margin-top: 10px;
    }
}
