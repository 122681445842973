.modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.4s;
    z-index: 100;
}
.modal.active {
    opacity: 1;
    pointer-events: all;
}

.modal__content {
    padding: 30px 30px 60px 30px;
    border-radius: 15px;
    transform: scale(0.5);
    transition: 0.3s all;
    max-width: 517px;
    display: flex;
    flex-direction: column;
}
.modal__content.active {
    transform: scale(1);
}