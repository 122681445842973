.success {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    max-width: 280px;
    line-height: 20.8px;
    margin: 30px 0;
}
.wrappImg {
    margin-top: 10px;
}