/* services */
.services {
    width: 100%
}
@media (min-width: 1000px) {
    .services {
       margin-top: 30px;
    }
    .wrapper {
        margin-bottom: 70px;
    }
}
@media (max-width: 999px) {
    .services {
        margin-top: 20px;
    }
    .wrapper {
        margin-bottom: 50px;
    }
}
.services h1 {
    margin-top: 30px;
}
@media (max-width: 1279px) {
    .services h1 {
        margin-top: 20px;
    }
}

.content {
    display: flex;
    flex-direction: column;
}
.content .service, .content .galleryInner, .inner {
    margin: 0 auto;
    max-width: 1280px;
}
@media (min-width: 1280px) {
    .content .service, .content .galleryInner, .inner {
        padding: 0 70px;
    }
}
@media (min-width: 1000px) and (max-width: 1279px) {
    .content .service, .content .galleryInner, .inner {
        padding: 0 60px;
    }
}
@media (min-width: 768px) and (max-width: 999px) {
    .content .service, .content .galleryInner, .inner {
        padding: 0 30px;
    }
}
@media (min-width: 480px) and (max-width: 767px) {
    .content .service, .content .galleryInner, .inner {
        padding: 0 25px;
    }
}
@media (max-width: 479px) {
    .content .service, .content .galleryInner, .inner {
        padding: 0 25px;
    }
}

/* GALLERY */
.content .gallery {
    order: -1;
    background: #F3F6FE;
    width: 100%;
    margin-top: 50px;
}
@media (max-width: 999px) {
    .content .gallery {
        margin-top: 30px;
    }
}
.content .galleryInner {
    margin: 30px auto;
}
@media (max-width: 767px) {
    .content .galleryInner {
        margin: 20px auto;
    }
}

/* SERVICE */
.content .service {
    margin: 70px auto 0;
}
.content .service:first-child {
    order: -2;
    margin-top: 30px;
    margin-bottom: 0 !important;
}
@media (max-width: 767px) {
    .content .service {
        margin: 50px auto 0;
    }
    .content .service:first-child {
        margin-top: 20px;
        margin-bottom: 0 !important;
    }
}