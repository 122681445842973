.main {
    position: relative;
    width: 100%;
    height: auto;
}
.content {
    max-width: 1280px;
}

@media (min-width: 1280px) {
    .main h1 {
        font-size: 34px;
    }
    .main h2 {
        font-size: 30px;
    }
    .content {
        padding: 0 70px;
        margin: 40px auto 70px;
    }
}
@media (min-width: 1000px) and (max-width: 1279px) {
    .main h1 {
        font-size: 34px;
    }
    .main h2 {
        font-size: 30px;
    }
    .content {
        padding: 0 60px;
        margin: 20px auto 70px;
    }
}
@media (min-width: 768px) and (max-width: 999px) {
    .main h1 {
        font-size: 27px;
    }
    .main h2 {
        font-size: 25px;
    }
    .content {
        padding: 0 30px;
        margin: 20px auto 50px;
    }
}
@media (min-width: 480px) and (max-width: 767px) {
    .main h1 {
        font-size: 24px;
    }
    .main h2 {
        font-size: 22px;
    }
    .content {
        padding: 0 25px;
        margin: 20px auto 50px;
    }
}
@media (max-width: 479px) {
    .main h1 {
        font-size: 19px;
    }
    .main h2 {
        font-size: 18px;
    }
    .content {
        padding: 0 25px;
        margin: 20px auto 50px;
    }
}