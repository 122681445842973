@media (min-width: 1000px) {
    .faq {
        margin-top: 70px;
    }
}
@media (min-width: 768px) and (max-width: 999px) {
    .faq {
        margin-top: 60px;
    }
}
@media (max-width: 767px) {
    .faq {
        margin-top: 50px;
    }
}
.questions {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
}
@media (max-width: 479px) {
    .questions {
        margin-top: 20px;
    }
}