.category {
    padding: 30px 10px;
    background: #F3F6FE;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.category h3 {
    text-align: center;
}
.image, .skeleton {
    height: 220px;
    width: 220px;
    margin: 30px 0;
}
@media (max-width: 1000px) {
    .image, .skeleton {
        height: 180px;
        width: 180px;
    }
}
@media (max-width: 850px) {
    .image, .skeleton {
        height: 220px;
        width: 220px;
    }
}
@media (max-width: 768px) {
    .image, .skeleton {
        height: 180px;
        width: 180px;
    }
}