.wrapper {
    font-family: "Raleway", sans-serif;
}

.containerQuestion {
    padding: 30px;
    background: #F3F6FE;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    gap: 15px;
    font-size: 20px;
}
.containerQuestion p {
    color: var(--main-color);
    font-weight: 500;
}
.containerQuestion span {
    margin-right: 5px;
}
.row {
    transition: 0.3s;
}
.rotated {
    transform: rotate(-90deg);
}
.containerAnswer {
    padding: 20px 30px;
    background: #F3F6FE;
    border-radius: 20px;
    margin-top: 10px;
    transition: 0.3s;
    display: none;
    font-size: 18px;
}
.containerAnswer.show {
    display: block;
}

.my-answer-enter {
    opacity: 0;
}
.my-answer-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}
.my-answer-exit {
    opacity: 1;
}
.my-answer-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

@media (min-width: 1000px) and (max-width: 1279px) {
    .containerQuestion {
        font-size: 20px;
    }
    .containerAnswer {
        font-size: 17px;
    }
}
@media (min-width: 768px) and (max-width: 999px) {
    .containerQuestion {
        font-size: 18px;
    }
    .containerAnswer {
        font-size: 17px;
    }
}
@media (min-width: 480px) and (max-width: 767px) {
    .containerQuestion {
        font-size: 17px;
    }
    .containerAnswer {
        font-size: 16px;
    }
}
@media (max-width: 479px) {
    .containerQuestion {
        font-size: 16px;
    }
    .containerAnswer {
        font-size: 15px;
    }
}

@media (max-width: 767px) {
    .containerQuestion {
        padding: 20px;
    }
    .containerAnswer {
        padding: 20px;
    }
}
