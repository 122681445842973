.btn {
    height: 45px;
    background: #fff;
    color: #3462C3;
    border: 1px solid #fff;
    border-radius: 20px;
    font-size: 18px;
    font-weight: 600;
    transition: 0.3s;
    display: flex;
    align-items: center;
    padding: 3px 25px 3px 3px;
    cursor: pointer;
}
.text {
    margin: 0 auto;
}

.btn:hover {
    color: #365BA7;
}
.btn:active {
    border: 1px solid #365BA7;
}

.btn:disabled {
    color: #fff;
    background: #C2C2C2;
    border: #C2C2C2;
    width: 183px;
}

.rowIcon {
    color: #fff;
}

.wrappRow {
    width: 33px;
    height: 33px;
    margin-right: 15px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: var(--main-color);
}

@media (max-width: 999px) {
    .btn {
        height: 40px;
        font-size: 17px;
    }
}
@media (max-width: 767px) {
    .btn {
        font-size: 16px;
    }
}
@media (max-width: 479px) {
    .btn {
        font-size: 15px;
    }
}