.wrapper {
    max-width: 1280px;
    width: 100%;
}
.wrapper h1 {
    margin-top: 30px;
}
@media (max-width: 999px) {
    .wrapper h1 {
        margin-top: 20px;
    }
}
@media (min-width: 1280px) {
    .wrapper {
        padding: 0 70px;
        margin: 30px auto 70px;
    }
}
@media (min-width: 1000px) and (max-width: 1279px) {
    .wrapper {
        padding: 0 60px;
        margin: 30px auto 70px;
    }
}
@media (min-width: 768px) and (max-width: 999px) {
    .wrapper {
        padding: 0 30px;
        margin: 20px auto 50px;
    }
}
@media (min-width: 480px) and (max-width: 767px) {
    .wrapper {
        padding: 0 25px;
        margin: 20px auto 50px;
    }
}
@media (max-width: 479px) {
    .wrapper {
        padding: 0 25px;
        margin: 20px auto 50px;
    }
}