.navigate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
}
.navigate a {
    color: #333;
    text-decoration: none;
    transition: background-color 0.3s ease;
    padding: 6px 8px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
}
.navigate a:hover {
    background-color: #f0f0f0;
}
@media (max-width: 999px) {
    .navigate {
        flex-direction: column;
        margin-top: 75px;
        margin-bottom: 30px;
        align-items: center;
    }
    .navigate a {
        font-weight: 600;
        font-size: 19px;
        color: var(--main-color);
        padding: 8px 0;
    }
}



