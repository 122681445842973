.wrapper {
    max-width: 1280px;
    width: 100%;
}

.categories {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin: 30px auto 0;
}

.categories h3 {
    font-size: 22px;
}

@media (max-width: 850px) {
    .categories {
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }
}
@media (max-width: 767px) {
    .categories h3 {
        font-size: 19px;
    }
}
@media (max-width: 550px) {
    .categories {
        grid-template-columns: 1fr;
    }
}

@media (min-width: 1280px) {
    .wrapper {
        padding: 0 70px;
        margin: 30px 0 70px;
    }
    .wrapper h1 {
        margin-top: 30px;
    }
}
@media (min-width: 1000px) and (max-width: 1279px) {
    .wrapper {
        padding: 0 60px;
        margin: 30px 0 70px;
    }
    .wrapper h1 {
        margin-top: 30px;
    }
    .categories {
        gap: 30px;
    }
}
@media (min-width: 768px) and (max-width: 999px) {
    .wrapper {
        padding: 0 30px;
        margin: 20px 0 50px;
    }
    .wrapper h1 {
        margin-top: 20px;
    }
    .categories {
        gap: 20px;
    }
}
@media (min-width: 480px) and (max-width: 767px) {
    .wrapper {
        padding: 0 25px;
        margin: 20px 0 50px;
    }
    .wrapper h1 {
        margin-top: 20px;
    }
    .categories {
        gap: 20px;
    }
}
@media (max-width: 479px) {
    .wrapper {
        margin: 20px 0 50px;
        padding: 0 25px;
    }
    .categories {
        gap: 20px;
    }
    .wrapper h1 {
        margin-top: 20px;
    }
}