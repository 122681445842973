.awards {
    overflow: hidden;
    display: flex;
    border-radius: 20px;
}
.awards .skeleton, .awards .image {
    width: 263px;
    height: 375px;
    transition: 0.3s;
}
.skeleton, .image:hover {
    transform: scale(1.05);
    cursor: url("../../../img/zoom-in.png"), auto;
}

@media (max-width: 1279px) {
    .awards .skeleton, .awards .image {
        width: 250px;
        height: 375px;
    }
}
@media (max-width: 999px) {
    .awards .skeleton, .awards .image {
        width: 200px;
        height: 300px;
    }
}
@media (max-width: 479px) {
    .awards .skeleton, .awards .image {
        width: 170px;
        height: 250px;
    }
}