@media (min-width: 480px) and (max-width: 767px) {
    .wrapper h2 {
        margin-bottom: 30px;
    }
}
@media (max-width: 479px) {
    .wrapper h2 {
        margin-bottom: 20px;
    }
}

@media (min-width: 1000px) {
    .wrapper {
        margin-top: 70px;
    }
}
@media (min-width: 768px) and (max-width: 999px) {
    .wrapper {
        margin-top: 60px;
    }
}
@media (max-width: 767px) {
    .wrapper {
        margin-top: 50px;
    }
}