.wrapper {
    display: flex;
    align-items: center;
    margin-top: 30px;
}
.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-left: 30px;
}
.image, .skeleton {
    object-fit: cover;
}
.inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}
.inner h3 {
    font-size: 22px;
    font-family: "Montserrat", sans-serif;
    max-width: 460px;
}
.wrap_characteristics {
    margin-top: 10px;
}
.wrap_characteristics strong {
    font-size: 16px;
}
.characteristics {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 280px;
    overflow: auto;
    align-content: start;
}
.characteristics p {
    font-size: 16px;
}
.characteristics div {
    margin-top: 10px;
    margin-right: 15px;
    max-width: calc(50% - 15px);
    line-height: 130%;
}
.characteristics div p:first-child {
    color: #565656;
}
.characteristics div p:last-child {
    margin-top: 1px;
    font-family: "Montserrat", sans-serif;
    color: #000;
}

@media (min-width: 1280px) {
    .image, .skeleton {
        width: 360px;
        height: 340px;
    }
}
@media (max-width: 1279px) {
    .image, .skeleton {
        width: 250px;
        height: 280px;
    }
    .content {
        margin-left: 20px;
    }
    .inner h3 {
        font-size: 22px;
    }
}
@media (max-width: 999px) {
    .image, .skeleton {
        width: 256px;
        height: 280px;
    }
    .inner h3 {
        font-size: 19px;
    }
    .cover {
        display: flex;
        margin-top: 20px;
        width: 100%;
    }
    .characteristics {
        height: auto;
        flex-wrap: nowrap;
    }
    .characteristics div {
        max-width: 100%;
    }

    .wrap_characteristics .btn {
        align-self: center;
        margin-top: 15px;
    }
}
@media (max-width: 767px) {
    .cover {
        flex-direction: column;
        align-items: center;
    }
    .image, .skeleton {
        width: 327px;
        height: 360px;
    }
    .wrapper {
        flex-direction: column;
    }
    .wrap_characteristics {
        background: #F3F6FE;
        border-radius: 10px;
        padding: 20px;
        display: flex;
        flex-direction: column;
    }
    .content {
        margin-left: 0;
        width: 100%;
    }
}
@media (max-width: 479px) {
    .image, .skeleton {
        width: 250px;
        height: 275px;
    }
}