.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    margin-top: 30px;
}
.wrapper .inner {
    width: 50%;
}
.wrapper .wrapSkeleton {
    width: 50%;
    height: 350px;
}
.wrapper .wrapSkeleton .image, .wrapper .wrapSkeleton .skeleton {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}
.wrapper .wrapSkeleton .skeleton {
    height: 350px;
}
.inner p {
    margin-top: 20px;
}
@media (max-width: 1279px) {
    .wrapper .wrapSkeleton .skeleton {
        height: 340px;
    }
    .wrapper {
        gap: 20px;
    }
    .wrapper .inner {
        width: 60%;
    }
}
@media (max-width: 999px) {
    .wrapper .wrapSkeleton .skeleton {
        height: 256px;
    }
    .wrapper {
        gap: 15px;
    }
    .wrapper .wrapSkeleton {
        height: 256px;
    }
    .wrapper .inner {
        width: 64%;
    }
    .inner p {
        margin-top: 15px;
    }
}
@media (max-width: 767px) {
    .wrapper {
        flex-direction: column;
    }
    .wrapper .inner {
        order: -1;
        width: 100%;
    }
    .wrapper .wrapSkeleton {
        width: 100%;
        height: auto;
    }
    .inner p {
        margin-top: 20px;
    }
    .wrapper .wrapSkeleton .skeleton {
        height: 230px;
    }
}
@media (max-width: 479px) {
    .wrapper .wrapSkeleton .skeleton {
        height: 200px;
    }
}