.skeleton {
    background-color: #e0e0e0;
    animation: pulse 1s infinite alternate;
}

@keyframes pulse {
    0% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}