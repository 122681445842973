@media (min-width: 1000px) {
    .wrapper {
        margin-top: 70px;
    }
}
@media (min-width: 768px) and (max-width: 999px) {
    .wrapper {
        margin-top: 60px;
    }
}
@media (max-width: 767px) {
    .wrapper {
        margin-top: 50px;
    }
}
.containerForVerySmall {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}