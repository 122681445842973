@media (min-width: 1000px) {
    .about {
        margin-top: 70px;
    }
}
@media (min-width: 768px) and (max-width: 999px) {
    .about {
        margin-top: 60px;
    }
}
@media (max-width: 767px) {
    .about {
        margin-top: 50px;
    }
}

/* container */
.container {
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 30px;
}
@media (min-width: 1000px) and (max-width: 1279px) {
    .container {
        gap: 20px;
    }
}
@media (max-width: 999px) {
    .container {
        gap: 15px;
    }
}
@media (max-width: 767px) {
    .container {
        flex-direction: column;
    }
}
@media (max-width: 479px) {
    .container {
        margin-top: 20px;
    }
}

/* imgContainer */
.imgContainer {
    width: 60%;
}
.image, .skeleton {
    object-fit: cover;
    width: 100%;
    height: 435px;
    border-radius: 20px;
}
@media (min-width: 1000px) and (max-width: 1279px) {
    .imgContainer {
        width: 50%;
    }
    .image, .skeleton {
        height: 290px;
    }
}
@media (min-width: 768px) and (max-width: 999px) {
    .image, .skeleton {
        height: 285px;
    }
}
@media (max-width: 767px) {
    .imgContainer {
        width: 100%;
    }
}
@media (min-width: 480px) and (max-width: 767px) {
    .image, .skeleton {
        height: 270px;
    }
}
@media (max-width: 479px) {
    .image, .skeleton {
        height: 230px;
    }
}

/* content */
.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    width: 40%;
}
.content button {
    align-self: end;
}
@media (min-width: 1000px) and (max-width: 1279px) {
    .content {
        width: 50%;
        gap: 15px;
    }
}
@media (min-width: 768px) and (max-width: 999px) {
    .content button {
        align-self: start;
    }
}
@media (max-width: 767px) {
    .content {
        width: 100%;
    }
    .content button {
        align-self: center;
    }
}
