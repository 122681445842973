.wrapper {
    position: relative;
}
.wrapper .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.wrapper {
    :global(.swiper) {
        overflow: visible !important;
        position: static !important;
    }
    :global(.swiper-slide) {
        width: auto !important;
    }
}

.rowIcon{
    width: 15px;
    color: var(--main-color);
}
.btns {
    display: flex;
}
.btns button {
    width: 45px;
    height: 45px;
    border: none;
    background: #F3F6FE;
    transform: rotate(90deg);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.btns button:last-child {
    transform: rotate(-90deg);
    margin-left: 20px;
}
.btns {
    :global(button.swiper-button-disabled) {
        cursor: default !important;
    }
    :global(button.swiper-button-disabled) .rowIcon {
        color: #C2C2C2 !important;
    }
}
