.nav {
    display: flex;
    flex-wrap: wrap;
}
.nav a {
    font-size: 14px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    margin: 0 10px;
}
.nav a:first-child {
    margin-left: 0;
}
.nav img {

}
.nav .homeImg {
    margin-right: 10px;
}
.crumbs {
    display: flex;
    align-items: center;
}
.crumbs .disabledLink{
    color: var(--main-color);
    font-family: "Raleway", sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
}

