.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.modalContent {
    overflow: hidden;
    display: flex;
    align-items: center; justify-content: center;
}
.modalContent .closeBtn {
    position: absolute;
    right: 20px;
    top: 20px;
}


.modalBackdrop {
    :global(.swiper-slide) {
        display: flex;
        justify-content: center;
    }
    :global(button.swiper-button-disabled) {
        cursor: default !important;
    }
    :global(button.swiper-button-disabled) .rowIcon {
        color: #C2C2C2 !important;
    }
    :global(.swiper-wrapper) {
        align-items: center;
    }
}

.swiper {
    position: relative;
    width: 1000px;
}
.selectedImage {
    max-height: 75vh;
    max-width: 960px;
}
@media (max-width: 999px) {
    .swiper {
        width: 768px;
    }
    .selectedImage {
        max-width: 728px;
    }
}
@media (max-width: 767px) {
    .swiper {
        width: 480px;
    }
    .selectedImage {
        max-width: 440px;
    }
}
@media (max-width: 479px) {
    .swiper {
        width: 100%;
    }
    .selectedImage {
        max-width: 80vw;
    }
}
/* BUTTONS */
.rowIcon {
    width: 15px;
    color: var(--main-color);
}
.prevBtn, .nextBtn {
    width: 45px;
    height: 45px;
    border: none;
    background: #FCFDFF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    z-index: 2;
}
.prevBtn {
    left: 10%;
    top: 50%;
    transform: rotate(90deg) translateX(-50%);
}
.nextBtn {
    transform: rotate(-90deg) translateX(50%);
    right: 10%;
    top: 50%;
}
@media (max-width: 479px) {
    .prevBtn, .nextBtn {
        width: 30px; height: 30px;
        opacity: 0.7;
    }
}