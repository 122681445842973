@media (min-width: 1000px) {
    .wrapper {
        margin-top: 70px;
    }
}
@media (min-width: 768px) and (max-width: 999px) {
    .wrapper {
        margin-top: 60px;
    }
}
@media (max-width: 767px) {
    .wrapper {
        margin-top: 50px;
    }
}

.container {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

/* BLOCK */
.block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(50% - 20px);
    padding: 0 30px;
}
.block img {
    width: 50px;
    height: 50px;
}
.block strong {
    font-size: 22px;
    margin-top: 20px;
}
.block p {
    margin-top: 10px;
    font-size: 18px;
    text-align: center;
}
@media (min-width: 768px) and (max-width: 999px) {
    .block {
        padding: 0;
    }
    .block strong {
        margin-top: 15px;
        font-size: 19px;
    }
    .block img {
        width: 40px;
        height: 40px;
    }
    .block p {
        font-size: 17px;
    }
}
@media (min-width: 480px) and (max-width: 767px) {
    .block strong {
        margin-top: 15px;
        font-size: 18px;
    }
    .block p {
        font-size: 17px;
    }

}
@media (max-width: 479px) {
    .block strong {
        margin-top: 15px;
        font-size: 17px;
    }
    .block p {
        font-size: 15px;
    }
    .container {
        margin-top: 20px;
    }
}

@media (max-width: 767px) {
    .container {
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }
    .block {
        width: 100%;
    }
}