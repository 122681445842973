.banner {
    background: var(--main-color);
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #F2F3FE;
    gap: 30px;
}
.buttons {
    display: flex;
    align-items: center;
}
.buttons a {
    border-bottom: 1px solid #F2F3FE;
    color: #F2F3FE;
}

@media (min-width: 1280px) {
    .banner {
        padding: 70px;
    }
    .content {
        width: 500px;
    }
    .buttons {
        gap: 30px;
    }
    .banner p {
        font-size: 24px;
        margin: 20px 0 30px;
    }
    .image, .skeleton {
        width: 350px;
        height: 350px;
    }
}
@media (min-width: 1000px) and (max-width: 1279px) {
    .banner {
        padding: 50px;
    }
    .content {
        width: 500px;
    }
    .buttons {
        gap: 30px;
    }
    .banner p {
        font-size: 24px;
        margin: 20px 0 30px;
    }
    .image, .skeleton {
        width: 300px;
        height: 300px;
    }
}
@media (min-width: 768px) and (max-width: 999px) {
    .banner {
        padding: 50px 25px;
        gap: 20px;
    }
    .content {
        width: 450px;
    }
    .buttons {
        gap: 20px;
    }
    .banner p {
        font-size: 22px;
        margin: 20px 0 30px;
    }
    .image, .skeleton {
        width: 230px;
        height: 230px;
    }
}
@media (min-width: 480px) and (max-width: 767px) {
    .banner {
        padding: 60px 40px;
        flex-direction: column;
        gap: 44px;
    }
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 355px;
    }
    .content h1 {
        text-align: center;
    }
    .banner p {
        font-size: 20px;
        margin: 15px 0 30px;
        text-align: center;
    }
    .buttons {
        flex-direction: column;
        gap: 20px;
    }
    .image, .skeleton {
        width: 320px;
        height: 350px;
    }
}
@media (max-width: 479px) {
    .banner {
        padding: 60px 20px;
        flex-direction: column;
        gap: 50px;
    }
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .content h1 {
        text-align: center;
    }
    .banner p {
        font-size: 17px;
        margin: 15px 0 20px;
        text-align: center;
    }
    .buttons {
        flex-direction: column;
        gap: 20px;
    }
    .image, .skeleton {
        width: 260px;
        height: 260px;
    }
}