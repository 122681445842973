.primary {
    width: 100%;
    height: 45px;
    background: #fff;
    border-radius: 30px;
    outline: none;
    padding: 11px 20px;
    font-size: 18px;
    font-weight: 400;
    border: none
}
.primary::placeholder {
    color: #929292;
}

@media (max-width: 768px) {
    .primary {
        height: 40px;
    }
}
@media (max-width: 320px) {
    .primary {
        height: 38px;
    }
}