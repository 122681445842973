.form {
    background: var(--main-color);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.wrapper {
    width: 1280px;
}
.wrappContent {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
}
.info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 30px;
    max-width: 560px;
}
.wrappText {
    margin-top: 30px;
}
.wrappText p:last-child {
    margin-top: 8px;
}
.wrappContact {
    display: flex;
    flex-direction: column;
    font-family: "Raleway", sans-serif;
}

.phoneNumber {
    font-family: "Montserrat", sans-serif;
    margin-top: 10px;
}
.phoneImg {
    color: #fff;
}

.wrappContactTablet {
    display: none;
    align-items: center;
    margin-top: 30px;
}

@media (min-width: 1280px) {
    .wrapper {
        padding: 70px 70px;
    }
}
@media (min-width: 1000px) and (max-width: 1279px) {
    .wrapper {
        padding: 70px 60px;
    }
}
@media (min-width: 768px) and (max-width: 999px) {
    .wrapper {
        padding: 50px 30px;
    }
}
@media (max-width: 767px) {
    .wrapper {
        padding: 50px 25px;
    }
    .wrappContent {
        flex-direction: column;
        align-items: center;
        margin-top: 0;
    }
    .wrappContact {
        display: none;
    }
    .wrappContactTablet {
        display: flex;
        flex-direction: column;
    }
    .wrappText {
        margin: 30px 0;
    }
    .info {
        margin: 0;
        align-self: start;
    }
}

