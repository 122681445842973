.about {
    width: 100%;
}
.wrapper {
    max-width: 1280px;
}
.wrapper h1 {
    margin-top: 30px;
}
@media (max-width: 999px) {
    .wrapper h1 {
        margin-top: 20px;
    }
}
@media (min-width: 1280px) {
    .wrapper, .awardsInner {
        padding: 0 70px;
        margin: 30px auto 70px;
    }
}
@media (min-width: 1000px) and (max-width: 1279px) {
    .wrapper, .awardsInner {
        padding: 0 60px;
        margin: 30px auto 70px;
    }
}
@media (min-width: 768px) and (max-width: 999px) {
    .wrapper, .awardsInner {
        padding: 0 30px;
        margin: 20px auto 50px;
    }
}
@media (min-width: 480px) and (max-width: 767px) {
    .wrapper, .awardsInner {
        padding: 0 25px;
        margin: 20px auto 50px;
    }
}
@media (max-width: 479px) {
    .wrapper, .awardsInner {
        padding: 0 25px;
        margin: 20px auto 50px;
    }
}

/* AWARDS */
.awards {
    background: #F3F6FE;
    width: 100%;
    margin-top: 70px;
    margin-bottom: 0 !important;
    padding: 70px 0;
}
.awards .awardsInner {
    margin: 0 auto;
    max-width: 1280px;
}
@media (max-width: 999px) {
    .awards {
        margin-top: 50px;
        padding: 50px 0;
    }
}

