footer {
    width: 100%;
    max-width: 1280px;
}
@media (min-width: 1280px) {
    footer {
        padding: 0 70px;
    }
}
@media (min-width: 1000px) and (max-width: 1279px) {
    footer {
        padding: 0 60px;
    }
}
@media (min-width: 768px) and (max-width: 999px) {
    footer {
        padding: 0 30px;
    }
}
@media (min-width: 480px) and (max-width: 767px) {
    footer {
        padding: 0 25px;
    }
}
@media (max-width: 479px) {
    footer {
        padding: 0 25px;
    }
}

.wrapper {
    display: flex;
    align-items: center;
    padding: 35px 0;
    gap: 20px;
    width: 100%;
}
.email {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    margin-top: 10px;
}
.logo {
    width: 156px;
    height: 100px;
}

/* socials */
.social {
    margin-top: 40px;
    width: 230px
}
.social p {
    font-weight: 500;
    font-size: 16px;
}

.social .links a {
    margin-top: 20px;
    display: inline-block;
    margin-right: 15px;
}
.social.tablet {
    display: none;
}

.navigation {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    gap: 15px;
}

.section {
    display: flex;
    flex-direction: column;
}

.section .title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    font-family: "Raleway", sans-serif;
}
.section a {
    margin-top: 10px;
}

.numberPhone {
    font-family: "Montserrat", sans-serif;
}

.contactMobile {
    display: none;
}

.bottom {
    height: 65px;
    border-top: 1px solid #868686;
    font-family: "Montserrat", sans-serif;
    justify-content: center;
    align-items: center;
    display: flex;
}
@media (max-width: 999px) {
    .social {
        display: none;
    }
    .social.tablet {
        display: block;
        margin-top: 20px;
    }
    .social.tablet a {
        display: inline-flex;
        width: 25px;
        height: 25px;
        margin-right: 10px;
        margin-top: 0;
    }
}
@media (max-width: 767px) {
    .email {
        font-size: 17px;
    }
    .wrapper {
        flex-direction: column;
        align-items: center;
    }
    .navigation .section:last-child {
        display: none;
    }
    .contactMobile {
        display: block;
    }
    .contactMobile div {
        display: flex;
    }
    .contactMobile div:first-child {
        flex-direction: column;
        text-align: center;
        margin-top: 10px;
    }
    .contactMobile div:first-child a {
        margin-top: 10px;
    }
    .contactMobile div:last-child {
        justify-content: space-evenly;
        margin-top: 20px;
    }
    .contactMobile div:last-child a {
        display: flex;
        width: 25px;
        height: 25px;
    }
    .wrapperLogo {
        text-align: center;
    }
    .section .title {
        margin-bottom: 5px;
    }
}

@media (max-width: 768px) {
    .email {
        font-size: 14px;
    }
    .logo {
        width: 125px;
        height: 80px;
    }
    .wrapper {
        padding: 30px 0;
    }
    .navigation {
        margin-top: 20px;
    }
    .section .title {
        font-size: 16px;
    }
    .section a {
        font-size: 14px;
    }
    .bottom {
        height: 55px;
    }
}

@media (max-width: 480px) {
    .email {
        font-size: 15px;
    }
}
@media (max-width: 360px) {
    .logo {
        width: 107px;
        height: 70px;
    }
    .navigation {
        margin-top: 15px;
    }
}



