.wrapper {
    margin-top: 70px;
}
.skeleton, .image {
    height: 555px;
    width: 263px;
    transition: 0.5s;
}
.scale {
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.skeleton, .image:hover {
    transform: scale(1.05);
    cursor: url("../../../img/zoom-in.png"), auto;
}
@media (max-width: 1279px) {
    .skeleton, .image {
        height: 450px;
        width: 250px;
    }
}
@media (max-width: 999px) {
    .wrapper {
        margin-top: 50px;
    }
    .skeleton, .image {
        width: 200px;
        height: 365px;
    }
}
@media (max-width: 767px) {
    .skeleton, .image {
        width: 280px;
        height: 510px;
    }
}
@media (max-width: 479px) {
    .skeleton, .image {
        width: 240px;
        height: 445px;
    }
}